<template>
    <modal ref="modalAnularCredito" icon="close" titulo="Anular Crédito" @guardar="anular">
        <div class="row mx-0my-2">
            <div class="col-12 text-center mb-3 text-muted2 f-400">
                Se anulara la deuda pendiente de este pedido.
            </div>
        </div>
        <div class="row">
            <div class="col-10 mx-auto">
                <label class="f-14">Justificación</label>
                <el-input v-model="justificacion" type="textarea" show-word-limit maxlength="250" :rows="3" />
            </div>
        </div>
    </modal>
</template>
<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-tienda'
export default {
    data(){
        return {
            justificacion:'',
            idPedido: null
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial/id_pedido',
        })
    },
    methods: {
        toggle(id = null){
            if(id != null){
                this.idPedido = id
            }else{
                this.idPedido = this.id_pedido
            }
            this.$refs.modalAnularCredito.toggle();
        },
        async anular(){
            try {
                if(this.justificacion.trim() === ''){
                    this.notificacion('Alerta','Por favor digite una justificacion','warning')
                    return
                }
                if(this.idPedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }

                let model = {
                    justificacion:this.justificacion
                }
                const {data} = await Pedidos.anular_credito(this.idPedido,model)
                this.notificacion('Mensaje','Credito Anulado','success')
                this.$emit('actualizar')
                this.$refs.modalAnularCredito.toggle();
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
